import React from "react"
import { Helmet } from "react-helmet"
import {
  NavBar,
  Footer,
} from '../components'
import {
  H1,
  FeatureHero,
  Container,
  TwoColumnContainer
} from '../styles/components'
import logo from '../images/logo_black.png'
import img from '../images/about_image.jpg'

export default function AboutPage() {
  return (
    <div className="aboutPage">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Turnkey | About</title>
        <link rel="canonical" href="https://turnkey-egy.com/about" />
      </Helmet>
      <NavBar activePage="about" background="white" logo={logo} />
      <FeatureHero>
        <section>
          <H1 center>Who We Are</H1>
          <hr className="divider"></hr>
          <p>We are a contracting company specializing in high-end design and fit out.</p>
          <p>Our team is comprised of experts in the fields of design, manufacturing, contracting, and finance.
            We work with clients on projects involving commercial, residential, hospitality, office spaces, and more.
          </p>
          <p>
            Our process involves ideation, design, budgeting, execution, and mechanical, electrical, and plumbing (MEP), 
            and we are capable of meeting your needs no matter what stage you're at in the process.
          </p>
        </section>
      </FeatureHero>
      <Container>
        <img className="aboutImage" src={img} />
      </Container>
      <TwoColumnContainer className="about">
        <div>
          <h1 style={{ textAlign: "center", marginBottom: "8px" }}>Our Values</h1>
          <hr className="divider"></hr>
          <p>
            Our goal is to maintain a culture of innovation and excellence in order to deliver outstanding solutions
            and drive long-lasting relationships with our clients.
          </p>
        </div>
        <div>
          <h1 style={{ textAlign: "center", marginBottom: "8px" }}>Our Mission</h1>
          <hr className="divider"></hr>
          <p>
            Our purpose is to become a leader in fit out design and contracting by bringing the latest international trends,
            materials, and cutting-edge technology to our market.
          </p>
        </div>
      </TwoColumnContainer>
      <Footer />
    </div>
  )
}
